import _orderBy from 'lodash/orderBy';

const _orderLayers = (layers) =>
  _orderBy(
    layers,
    [
      (layer) => {
        return layer.properties.layerOrder;
      },
    ],
    ['asc'],
  );

const _formatLayer = (layer) => ({
  id: layer.id,
  name: layer.name,
  type: layer.type,
  properties: Object.entries(layer.properties).map(([key, value]) => ({
    key,
    value,
    check: layer.checkProperties.indexOf(key) > -1,
  })),
  options: layer.options,
  children: _orderLayers(layer.children).map((childLayer) =>
    _formatLayer(childLayer),
  ),
});

const _formatComponentsLayer = (layer) => {
  return {
    id: layer.id,
    name: layer.name,
    type: layer.type,
    properties: layer.properties
      ? Object.entries(layer.properties).map(([key, value]) => ({
          key,
          value,
        }))
      : [],
    children: layer.children.map((childLayer) =>
      _formatComponentsLayer(childLayer),
    ),
  };
};

const _formatTask = (task) => ({
  id: task.id,
  name: task.name,
  text: task.text || '',
  preview: task.preview,
  layers: task.layers
    ? _orderLayers(task.layers).map((layer) => _formatLayer(layer))
    : [],
  // withTheory: task.theoryBlocks && !!task.theoryBlocks.length,
  withHints: task.hintsBlocks && !!task.hintsBlocks.length,
  withMaterials:
    task.materials &&
    !!task.materials.filter((material) => material.check).length,
  // theoryBlocks: task.theoryBlocks || [],
  hintsBlocks: task.hintsBlocks || [],
  materials: task.materials || [],
});

export const convertDataToForm = (data, readOnly) => {
  return {
    id: data.id,
    type: data.type,
    kind: data.kind,
    moduleId: data.moduleId,
    courseId: data.courseId,
    status: data.status,
    fileUrl: '',
    fileId: data.fileId,
    filePageId: data.filePageId,
    designType: data.designType || null,
    title: data.title || '',
    description: data.description || [],
    percentToComplete: data.percentToComplete,
    resultRefPreview: data.resultRefPreview,
    initialState: data.initialState
      ? {
          preview: data.initialState.preview,
          componentsLayer: data.initialState.componentsLayer
            ? _formatComponentsLayer(data.initialState.componentsLayer)
            : null,
          layers: _orderLayers(data.initialState.layers).map((layer) =>
            _formatLayer(layer),
          ),
        }
      : null,
    tasks: data.tasks ? data.tasks.map((task) => _formatTask(task)) : [],
    free: data.free,
    withGoodToKnow:
      data.goodToKnowBlocks && data.goodToKnowBlocks.length > 0 ? true : false,
    goodToKnowBlocks: data.goodToKnowBlocks || [],
    readOnly,
  };
};
