export const propertiesOptions = [
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Color',
    value: 'color',
  },
  {
    label: 'Star Ratio',
    value: 'starRatio',
  },
  {
    label: 'Star Points Count',
    value: 'starPointsCount',
  },
  {
    label: 'Ellipse Start',
    value: 'ellipseStart',
  },
  {
    label: 'Stroke Weight',
    value: 'strokeWeight',
  },
  {
    label: 'Start Point and End Point',
    value: 'startPointEndPoint',
  },
];
